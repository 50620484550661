import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { Auth } from '../../../../../decorators/auth.decorator';
import { CurrentUser } from '../../../../../decorators/current-user.decorator';
import { ModalClose } from '../../../../../decorators/modal-close.decorator';
import { RestaurantEmbed } from '../../../../../domains/restaurant-embed';
import { RestaurantSimple } from '../../../../../domains/restaurant-simple';
import { User } from '../../../../../domains/user';
import { ModalCloseReasons } from '../../../../../models/enums/modal-close-reasons';
import { ModalType } from '../../../../../models/enums/modal-type';
import { PickerCustomOption } from '../../../../../models/enums/picker-custom-option';
import { TourType } from '../../../../../models/enums/tour-type';
import { Image as ImageModel } from '../../../../../models/image';
import { Modal } from '../../../../../models/modal';
import { Picker } from '../../../../../models/picker';
import { PickerConfig } from '../../../../../models/picker-config';
import { PickerGroup } from '../../../../../models/picker-group';
import { UnsplashImage } from '../../../../../models/unsplash-image';
import { ContactService } from '../../../../../services/contact.service';
import { LoaderService } from '../../../../../services/loader.service';
import { ModalService } from '../../../../../services/modal.service';
import { NavigatorService } from '../../../../../services/navigator.service';
import { NotificationService } from '../../../../../services/notification.service';
import { RestaurantService } from '../../../../../services/restaurant.service';

@Component({
  selector: 'app-preview-modal-footer',
  templateUrl: './preview-modal-footer.component.html',
  styleUrls: ['./preview-modal-footer.component.scss']
})
export class PreviewModalFooterComponent implements OnInit {
  @Input() restaurant: RestaurantSimple;
  @Input() isBasket = false;
  @Input() isHoursEnabled = false;
  @Input() imageSelectModalTitle: string;
  @Input() existImage: ImageModel;

  @Output() hoursToggle = new EventEmitter<boolean>();
  @Output() goToRestaurantPage = new EventEmitter<boolean>();
  @Output() isHeroModalOpen = new EventEmitter<boolean>();
  @Output() save = new EventEmitter<ImageModel>();
  @Output() videoAdded = new EventEmitter<RestaurantEmbed>();

  @ViewChild('qrModalRef') qrModalRef: ElementRef;
  @ViewChild('imageSelectModalRef') imageSelectModalRef: ElementRef;
  @ViewChild('ownerVerificationModalRef') ownerVerificationModalRef: ElementRef;
  @ViewChild('videoReportModalRef') videoReportModalRef: ElementRef;
  @ViewChild('videoModalRef') videoModalRef: ElementRef;

  @CurrentUser() currentUser: User;

  videoModal: Modal;
  imageSelectModal: Modal;
  backgroundImage: string | UnsplashImage;
  restaurantPicker = new Picker();
  menuPicker: Picker;
  videoModalType = TourType;
  isHeroPageOpen = false;
  ownerVerificationModal: Modal;

  constructor(
    private contactService: ContactService,
    private modalService: ModalService,
    private restaurantService: RestaurantService,
    private navigatorService: NavigatorService,
    private loaderService: LoaderService,
    private notificationService: NotificationService,
  ) {
  }

  ngOnInit(): void {
    if (this.restaurant) {
      this.restaurantPicker.addDefaultOption(PickerCustomOption.RESTAURANT_LINK_VIDEO, 'Link video');
      this.restaurantPicker.addDefaultOption(PickerCustomOption.RESTAURANT_EDIT, 'Edit');
      this.restaurantPicker.addDefaultOption(PickerCustomOption.RESTAURANT_CALL, 'Call');
      this.restaurantPicker.addDefaultOption(PickerCustomOption.RESTAURANT_MAP, 'Map');
      this.restaurantPicker.addDefaultOption(PickerCustomOption.RESTAURANT_IMAGE_SEARCH, 'Image search');
    }

    this.restaurantService.getRestaurantMenus(this.restaurant.id)
      .subscribe(pickerMenus => {
        this.menuPicker = new Picker(plainToClass(PickerConfig, {groups: pickerMenus}));

        const linksGroup = new PickerGroup('Links');
        linksGroup.addOption(PickerCustomOption.MENU_WEB_IMAGES, 'Web images');
        linksGroup.addOption(PickerCustomOption.MENU_PHOTOS_MAP, 'Photos | Map');

        if (!this.restaurant.published) {
          this.menuPicker.optionGroups.push(linksGroup);
        }
      });
  }

  openVideoReportModal() {
    this.modalService.open(this.videoReportModalRef, ModalType.RESTAURANT_ADD);
  }

  pickerClick() {
    this.notificationService.settingsAndCameraClicked();
  }

  onPickerSelected(value: string) {
    switch (value) {
      case PickerCustomOption.RESTAURANT_LINK_VIDEO:
        this.linkVideo();
        break;
      case PickerCustomOption.RESTAURANT_EDIT:
        this.openVideoReportModal();
        break;
      case PickerCustomOption.RESTAURANT_CALL:
        this.onClickCall();
        break;
      case PickerCustomOption.RESTAURANT_MAP:
        this.openGoogleMap();
        break;
      case PickerCustomOption.RESTAURANT_IMAGE_SEARCH:
        this.navigatorService.goToGoogleImagesSearch(this.restaurant);
        break;
    }
  }

  openGoogleMap() {
    this.navigatorService.goToMapsRestaurant(this.restaurant);
  }

  openMenu(value: string) {
    switch (value) {
      case PickerCustomOption.MENU_WEB_IMAGES:
        this.navigatorService.goToGoogleImagesSearch(this.restaurant);
        break;
      case PickerCustomOption.MENU_PHOTOS_MAP:
        this.navigatorService.goToGoogleImages(this.restaurant);
        break;
      default:
        this.onClickFood(value);
        break;
    }
  }

  @ModalClose({reason: ModalCloseReasons.NEXT_NAVIGATE})
  goToRestaurant() {
    this.navigatorService.goToRestaurant(this.restaurant.hostname, {'first-menu': true});
  }

  onClickFood(foodUrl: string) {
    this.modalClose();
    this.navigatorService.goToUrl('/' + this.restaurant.hostname + foodUrl).then();
  }

  onInstagramImageSelect($event) {
    this.imageSelectModal.close();
    this.save.emit($event);
  }

  @Auth()
  ownerVerification() {
    this.ownerVerificationModal = this.modalService.open(this.ownerVerificationModalRef, ModalType.PREVIEW);
  }

  @Auth()
  openImageSelectModal() {
    this.imageSelectModal = this.modalService.open(this.imageSelectModalRef, ModalType.IMAGE_SELECT);
  }

  onClickShare() {
    this.loaderService.showLoader();

    this.contactService.share(this.restaurant);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 5000);
  }

  onClickCall() {
    this.loaderService.showLoader();

    this.contactService.call(this.restaurant);

    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 5000);
  }

  @ModalClose()
  modalClose() {
  }

  @ModalClose()
  openQRModal() {
    this.modalService.open(this.qrModalRef, ModalType.FULL_TRANSPARENT);
  }

  onVideoAdded(userEmbed: RestaurantEmbed) {
    this.closeVideoModal();

    this.videoAdded.emit(userEmbed);
  }

  closeVideoModal() {
    this.videoModal.close();
  }

  onHoursToggleClick() {
    this.hoursToggle.emit(!this.isHoursEnabled);
  }

  goToRestaurantFoodNew(image: ImageModel) {
    this.modalClose();

    setTimeout(() => {
      this.navigatorService.goToRestaurantFoodNew(this.restaurant.hostname, image);
    }, 500);
  }

  @Auth()
  linkVideo() {
    this.videoModal = this.modalService.open(this.videoModalRef, ModalType.TOP_ROUNDED);
  }
}
